<template>
  <div :id="containerId" class="tag-container">
    <!-- Tag item list -->
    <div
      class="tag-item"
      v-for="tag in addedTags"
      :key="tag.record_tag_id"
      @click="() => removeTag(tag)"
    >
      {{ tag.name }}
    </div>

    <!-- Add-button -->
    <div :id="btnId">
      <b-icon icon="plus" />
    </div>

    <!-- Popover -->
    <b-popover
      :target="btnId"
      triggers="click"
      placement="auto"
      :container="containerId"
    >
      <div>
        <div class="tag-container">
          <div
            class="tag-item"
            v-for="tag in unusedTags"
            :key="tag.record_tag_id"
            @click="() => addTag(tag)"
          >
            {{ tag.name }}
          </div>
        </div>
        <hr />

        <FormTagAdd @submit="() => reloadTags()" />
        <b-link to="/manage">
          Manage tags
        </b-link>
      </div>
    </b-popover>
  </div>
</template>

<script>
import FormTagAdd from "@/components/forms/FormTagAdd";
import store from "@/store";

export default {
  name: "JugglTagField",
  components: {
    FormTagAdd
  },
  props: {
    recordId: {
      required: true
    },
    onlyVisible: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      record: {}
    };
  },
  created: function() {
    this.record = store.getters.getRecordById(this.recordId);
  },
  methods: {
    getTagById: function(id) {
      return store.getters.getTagById(id);
    },
    addTag: function(tag) {
      this.record.tags.push(tag);
      store.dispatch("addTagToRecord", {
        tagId: tag.record_tag_id,
        recordId: this.recordId
      });
    },
    reloadTags: function() {
      store.dispatch("loadTags");
    },
    removeTag: function(tag) {
      const index = this.record.tags.indexOf(tag);
      if (index > -1) {
        this.record.tags.splice(index, 1);
        store.dispatch("removeTagFromRecord", {
          tagId: tag.record_tag_id,
          recordId: this.recordId
        });
      }
    }
  },
  computed: {
    unusedTags: function() {
      return Object.values(this.allTags).filter(
        tag => !this.usedTagIds.includes(tag.record_tag_id)
      );
    },
    allTags: function() {
      if (this.onlyVisible) {
        return store.getters.getFilteredTags({ visible: true });
      } else {
        return store.getters.tags;
      }
    },
    addedTags: function() {
      return Object.values(this.record.tags).filter(
        t => !this.onlyVisible || t.visible
      );
    },
    usedTagIds: function() {
      var ids = [];
      Object.values(this.addedTags).forEach(tag => {
        ids.push(tag.record_tag_id);
      });
      return ids;
    },
    containerId: function() {
      return "added-tags-for-" + this.record.record_id;
    },
    btnId: function() {
      return "add-tags-for-" + this.record.record_id;
    }
  }
};
</script>

<style lang="sass" scoped>
@import '@/style/theme.sass'

.tag-container
  display: flex
  flex-direction: row
  flex-wrap: wrap
  justify-content: left
  align-content: flex-start

  > *
    margin: 2px
    border-radius: 5px
    padding: 1px 6px
    text-align: center
    cursor: pointer
    border: 1px solid $grey

    &:hover
      background-color: $grey
      color: $background-primary

  > .tag-item
    border: 1px solid $primary

    &:hover
      background-color: $primary
      color: $white
</style>
