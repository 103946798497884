<template>
  <div>
    <main>
      <BaseLogo id="logo" size="medium" center class="space-top space-bottom-small" icon-only />
      <BaseContainer
        :width="width"
        center
        class="space-bottom"
        :class="{ 'center-content': center }"
      >
        <BaseTitle
          v-if="title"
          center
          size="giant"
          class="centered space-bottom"
          outline
        >
          {{ title.toUpperCase() }}
        </BaseTitle>
        <slot />
      </BaseContainer>
    </main>
    <LayoutFooter />
  </div>
</template>

<script>
import BaseContainer from "@/components/base/BaseContainer";
import BaseLogo from "@/components/base/BaseLogo";
import BaseTitle from "@/components/base/BaseTitle";
import LayoutFooter from "@/components/layout/LayoutFooter";

export default {
  name: "LayoutMinimal",
  components: {
    BaseContainer,
    BaseLogo,
    BaseTitle,
    LayoutFooter
  },
  props: {
    width: {
      default: "slim",
      type: String
    },
    title: {
      default: "",
      type: String
    },
    center: {
      default: false,
      required: false,
      type: Boolean
    }
  }
};
</script>

<style lang="sass" scoped>
.space-top
  margin-top: 5rem

.space-bottom
  margin-bottom: 5rem

.center-content
  text-align: center

.space-bottom-small
  margin-bottom: 1rem
</style>
