<template>
  <LayoutMinimal title="Login" width="slimmer">
    <FormLogin />
  </LayoutMinimal>
</template>

<script>
// @ is an alias to /src
import LayoutMinimal from "@/components/layout/LayoutMinimal";
import FormLogin from "@/components/forms/FormLogin";

export default {
  name: "Login",
  components: {
    LayoutMinimal,
    FormLogin
  }
};
</script>
