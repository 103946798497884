<template>
  <router-view />
</template>

<script>
export default {
  name: "App"
};
</script>

<style lang="sass">
// Import custom SASS variable overrides, or alternatively
// define your variable overrides here instead
@import '@/style/theme.sass'
</style>
