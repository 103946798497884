<template>
  <BaseColorText :text="project.name" :color="project.color" />
</template>

<script>
import BaseColorText from "@/components/base/BaseColorText.vue";

export default {
  name: "JugglProjectName",
  components: {
    BaseColorText
  },
  props: {
    project: {
      required: true,
      type: Object
    }
  }
};
</script>

<style></style>
