<template>
  <LayoutMinimal title="Tools">
    <BaseTitle>
      Graph Day Distribution
      <br />
      <small>by Linus Kämmerer</small>
    </BaseTitle>
    <BaseSection>
      <BaseTitle size="tiny">
        <b-link
          href="https://gist.github.com/linuskmr/f89ac638da036e25d67e147ece67577c"
        >
          <b-icon-github></b-icon-github>
          GitHub
        </b-link>
      </BaseTitle>
      <p class="monospace">
        Python script to generate a time distribution graph from the exported
        Juggl data. It accumulates the tracked record and shows the total
        working hours for different times for each weekday.
      </p>
    </BaseSection>

    <BaseSection>
      <BaseTitle>
        JSON to CSV
        <br />
        <small>by Linus Kämmerer</small>
      </BaseTitle>
      <BaseTitle size="tiny">
        <b-link
          href="https://gist.github.com/linuskmr/856459cdf6c7ea460503b92e00b2aa26"
        >
          <b-icon-github></b-icon-github>
          GitHub
        </b-link>
      </BaseTitle>
      <p class="monospace">
        Python script to convert exported Juggl data from a JSON to a CSV file.
      </p>
    </BaseSection>
  </LayoutMinimal>
</template>

<script>
// @ is an alias to /src
import LayoutMinimal from "@/components/layout/LayoutMinimal";
import BaseTitle from "@/components/base/BaseTitle";
import BaseSection from "../components/base/BaseSection.vue";

export default {
  name: "ToolsDocumentation",
  components: {
    LayoutMinimal,
    BaseTitle,
    BaseSection
  }
};
</script>

<style lang="sass" scoped></style>
