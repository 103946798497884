<template>
  <LayoutNavbarPrivate>
    <BaseSection v-if="runningRecords.length > 0" title="Tracking">
      <JugglRecordsList :records="runningRecords" running />
    </BaseSection>
    <BaseSection title="Projects">
      <div v-if="availableProjects.length > 0">
        <JugglProjectsPanel :projects="availableProjects" />
      </div>
      <div id="add-project-form">
        <FormProjectAdd />
        <b-link to="/manage">
          Manage projects
        </b-link>
      </div>
    </BaseSection>
    <BaseSection v-if="finishedRecords.length > 0" title="History">
      <JugglRecordsList :records="finishedRecords" />
      <div class="center">
        <b-button to="/history" variant="outline-secondary" center
          >Show all</b-button
        >
      </div>
    </BaseSection>
  </LayoutNavbarPrivate>
</template>

<script>
import LayoutNavbarPrivate from "@/components/layout/LayoutNavbarPrivate";
import JugglProjectsPanel from "@/components/juggl/JugglProjectsPanel";
import JugglRecordsList from "@/components/juggl/JugglRecordsList";
import FormProjectAdd from "@/components/forms/FormProjectAdd";
import BaseSection from "@/components/base/BaseSection";
import store from "@/store";

export default {
  name: "Home",
  components: {
    LayoutNavbarPrivate,
    JugglProjectsPanel,
    JugglRecordsList,
    FormProjectAdd,
    BaseSection
  },
  computed: {
    availableProjects: () => {
      return store.getters.getFilteredProjects({
        finished: true,
        visible: true
      });
    },
    finishedRecords: () => {
      return store.getters.getFilteredRecords({
        running: false,
        projectVisible: true
      });
    },
    runningRecords: () => {
      return store.getters.getFilteredRecords({
        running: true,
        projectVisible: true
      });
    }
  },
  created: () => {
    store.dispatch("loadProjects");
    store.dispatch("loadTags");
    store.dispatch("loadRunningRecords");
    store.dispatch("loadRecords", { limit: 10, finished: true, visible: true });
  }
};
</script>

<style lang="sass">
.center
  text-align: center

.bold
  font-weight: bold

#add-project-form
  margin-top: 1rem
  text-align: center
</style>
