import Vue from "vue";
import Vuex from "vuex";
import { juggl } from "./modules/juggl";
// TODO: Remove dependency from packages
// import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    juggl
  }
  // plugins: [
  //   createPersistedState({
  //     storage: window.localStorage
  //   })
  // ]
});
