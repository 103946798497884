<template>
  <b-form @submit="submitForm">
    <b-form-group id="id-group" label-for="id" label="Tag ID">
      <b-form-input id="id" v-model="form.record_tag_id" required trim disabled>
      </b-form-input>
    </b-form-group>
    <b-form-checkbox id="visible" v-model="form.visible" dark>
      Visible
    </b-form-checkbox>
    <b-form-group id="name-group" label-for="name" label="Name">
      <b-form-input id="name" v-model="form.name" required trim> </b-form-input>
    </b-form-group>
    <b-form-invalid-feedback :state="!failed">
      Something went wrong.
    </b-form-invalid-feedback>
    <b-button variant="primary" type="submit" class="right" :disabled="working">
      <b-spinner v-if="working" small />
      Save
    </b-button>
    <b-button variant="outline-secondary" @click="() => cancel()" class="left">
      Cancel
    </b-button>
    <BaseConfirmButton
      class="left"
      @click="() => deleteTag()"
      variant="outline-danger"
    >
      <b-icon class="icon-btn" icon="trash" />
    </BaseConfirmButton>
  </b-form>
</template>

<script>
import BaseConfirmButton from "@/components/base/BaseConfirmButton";
import store from "@/store";

export default {
  name: "FormTagDetails",
  components: {
    BaseConfirmButton
  },
  props: {
    tag: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      failed: false,
      working: false,
      form: {
        record_tag_id: undefined,
        name: undefined,
        visible: undefined
      }
    };
  },
  methods: {
    /**
     * Submits the form. Assupmtion: Form is valid, based on required flags.
     */
    submitForm: function(e) {
      e.preventDefault();
      this.failed = false;
      this.working = true;

      store
        .dispatch("updateTag", this.form)
        .then(() => {
          this.working = false;
          this.$emit("submit");
        })
        .catch(() => {
          this.failed = true;
          this.working = false;
        });

      return false;
    },
    cancel: function() {
      this.$emit("cancel");
    },
    deleteTag: function() {
      store.dispatch("removeTag", this.form.record_tag_id);
    }
  },
  created: function() {
    this.form.record_tag_id = this.tag.record_tag_id;
    this.form.name = this.tag.name;
    this.form.visible = this.tag.visible;
  }
};
</script>

<style lang="sass">
.left
    float: left !important
    margin-right: 1rem

.right
    float: right !important
    margin-left: 1rem
</style>
