import { render, staticRenderFns } from "./FormProjectDetails.vue?vue&type=template&id=026bdcfc&"
import script from "./FormProjectDetails.vue?vue&type=script&lang=js&"
export * from "./FormProjectDetails.vue?vue&type=script&lang=js&"
import style0 from "./FormProjectDetails.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports