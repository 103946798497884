<template>
  <b-form @submit="submitForm">
    <b-form-group id="username-group" label-for="username">
      <b-form-input
        id="username"
        v-model="form.user_id"
        required
        placeholder="User ID"
        trim
      >
      </b-form-input>
    </b-form-group>
    <b-form-group id="password-group" label-for="password">
      <b-form-input
        id="password"
        v-model="form.api_key"
        type="password"
        required
        placeholder="API Key"
      >
      </b-form-input>
    </b-form-group>
    <b-form-invalid-feedback :state="!failed">
      Password or email invalid.
    </b-form-invalid-feedback>
    <b-button variant="primary" type="submit" block :disabled="working">
      <b-spinner v-if="working" small />
      Log in
    </b-button>
  </b-form>
</template>

<script>
import store from "@/store";

export default {
  name: "FormLogin",
  data() {
    return {
      form: {
        user_id: null,
        api_key: null
      },
      failed: false,
      working: false
    };
  },
  methods: {
    /**
     * Submits the form. Assupmtion: Form is valid, based on required flags.
     */
    submitForm: function(e) {
      e.preventDefault();
      this.failed = false;
      this.working = true;

      // Try to login
      store
        .dispatch("login", {
          userId: this.form.user_id,
          apiKey: this.form.api_key
        })
        .then(r => {
          if (r !== true) {
            this.failed = true;
            this.working = false;
            return false;
          }

          // On success redirect to target or dashboard
          var target = "/";
          if (this.$route.query.redirect) target = this.$route.query.redirect;
          this.$router.push(target);
        })
        .catch(e => {
          console.log(e);
          this.failed = true;
          this.working = false;
        });

      return false;
    }
  }
};
</script>

<style></style>
