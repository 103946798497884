/**
 * Contains a bunch of helper functions.
 */
export const helperService = {
  /**
   * Converts number into a human readable percent integer.
   *
   * @param {*} r Floating point percent number
   */
  asPercent(r) {
    return Math.floor(r * 100);
  },

  /**
   * Converts number into a human readable floating point number with a digit behind the delimiter.
   *
   * @param {*} r Floating point number
   */
  asFloat(r) {
    return Math.floor(r * 10) / 10;
  },

  /**
   * Converts timestamp into a human readable date format.
   *
   * @param {*} d ISO Timestamp
   */
  asDateFromISO(d) {
    return helperService.asDateFromObj(new Date(d));
  },

  /**
   * Converts timestamp into a human readable date format.
   *
   * @param {*} d Date object
   */
  asDateFromObj(d) {
    return new Intl.DateTimeFormat("de").format(d);
  },

  /**
   * Makes sure, that the given text is not too long.
   *
   * @param {*} text Some text that might be shortened
   * @param {*} maxLength Max number of characters
   *
   * @returns The shortened or same text
   */
  keepItShort(text, maxLength = 20, ellipsis = "...") {
    if (text.length > maxLength) {
      // Adding ellipsis
      var short = text.substring(0, maxLength - ellipsis.length).trim();
      return short + ellipsis;
    } else return text;
  },

  /**
   * Converts a date object into a date-time-timezone string, and sets times and timezone to zero.
   *
   * @source https://stackoverflow.com/a/43528844/7376120
   *
   * @param {*} date A date object
   *
   * @returns Date as string in the used format
   */
  toISODate(date) {
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date
        .getDate()
        .toString()
        .padStart(2, "0") +
      "T00:00.000Z"
    );
  },

  /**
   * Converts a date object into a date-time-timezone string and only keeps the date.
   *
   * @param {*} date A date object
   *
   * @returns Date as string in YYYY-MM-DD format
   */
  toISODateOnly(date) {
    return helperService.toISODate(date).substring(0, 10);
  },

  /**
   * Adds current duration to a record.
   * Copied from original juggl code.
   * @param {*} record The record instance to update.
   */
  addDuration(record) {
    if (record.end_time != null) return record;

    record.duration =
      (new Date().getTime() - new Date(record.start_time).getTime()) / 1000;

    return record;
  },

  /**
   * Converts a datetime object into the necessary string format for server requests.
   * Copied from original juggl code.
   * @param {*} date
   */
  dateToString(date) {
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      date.getDate() +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes() +
      ":" +
      date.getSeconds()
    );
  },

  dateAsFilenameString(date) {
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      date.getDate() +
      "_" +
      date.getHours() +
      "-" +
      date.getMinutes() +
      "-" +
      date.getSeconds()
    );
  },

  getDurationTimestamp(totalSeconds) {
    totalSeconds = Math.ceil(totalSeconds);
    var days = Math.floor(totalSeconds / 86400);
    var hours = Math.floor((totalSeconds - days * 86400) / 3600);
    var minutes = Math.floor((totalSeconds - days * 86400 - hours * 3600) / 60);
    var seconds = totalSeconds - days * 86400 - hours * 3600 - minutes * 60;

    if (days < 10) {
      days = "0" + days;
    }
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    var timestamp = minutes + ":" + seconds;
    if (totalSeconds >= 3600) {
      timestamp = hours + ":" + timestamp;
    }
    if (totalSeconds >= 86400) {
      timestamp = days + ":" + timestamp;
    }
    return timestamp;
  },

  toISOTime(dateTime) {
    return (
      dateTime.getHours() +
      ":" +
      dateTime.getMinutes() +
      ":" +
      dateTime.getSeconds()
    );
  },

  calcDurationInSeconds(start, end = new Date()) {
    return (new Date(end).getTime() - new Date(start).getTime()) / 1000;
  }
};
