<template>
  <div>
    <header>
      <BaseContainer width="wide" center>
        <ul id="header-container">
          <li>
            <BaseLogo id="logo" size="normal" />
          </li>
          <li class="right">
            <BaseUserDropdown />
          </li>
        </ul>
      </BaseContainer>
    </header>
    <main>
      <BaseContainer
        width="medium"
        center
        :class="{ 'center-content': center }"
      >
        <BaseTitle v-if="title" center size="giant" class="centered" outline>
          {{ title.toUpperCase() }}
        </BaseTitle>
        <slot />
      </BaseContainer>
    </main>
    <LayoutFooter/>
  </div>
</template>

<script>
import BaseContainer from "@/components/base/BaseContainer.vue";
import BaseLogo from "@/components/base/BaseLogo.vue";
import BaseUserDropdown from "@/components/base/BaseUserDropdown.vue";
import BaseTitle from "@/components/base/BaseTitle";
import LayoutFooter from "@/components/layout/LayoutFooter";

export default {
  name: "LayoutNavbarPrivate",
  components: {
    BaseContainer,
    BaseLogo,
    BaseUserDropdown,
    BaseTitle,
    LayoutFooter
  },
  props: {
    title: {
      default: "",
      type: String
    },
    center: {
      default: false,
      required: false,
      type: Boolean
    }
  }
};
</script>

<style lang="sass" scoped>
@import '@/style/theme.sass'

header
  width: 100%
  position: fixed
  top: 0px
  box-shadow: 0px 0px 2rem 1rem #000D
  height: $navbar-height
  background-color: $background-primary
  z-index: 100

#header-container
  padding: 0px 20px
  margin: auto
  list-style: none
  line-height: navbar-height

  .right
    float: right

  *
    line-height: $navbar-height
    vertical-align: baseline
    display: inline-block

main
  padding: 0px 20px
  margin-top: 6rem
  margin-bottom: 6rem

footer
  margin-bottom: 3rem
  text-align: center

  *
    margin-left: 1rem
    margin-right: 1rem

.center-content
  text-align: center
</style>
