<template>
  <b-table
    :items="aggregatedStatistics"
    primary-key="day"
    hover
    :busy="isLoading"
    :fields="statistic_fields"
    sort-by="day"
    sort-desc
  >
    <template #table-busy>
      <div class="text-center">
        <b-spinner></b-spinner>
      </div>
    </template>

    <!-- Custom data -->
    <template #cell(duration)="data">
      {{ getDurationTimestamp(data.item.duration) }}
    </template>

    <template #cell(distribution)="data">
      <JugglProjectDistribution :projects="data.item.projects" />
    </template>

    <template #cell(details)="data">
      <b-button
        size="sm"
        @click="data.toggleDetails"
        variant="outline-secondary"
      >
        <b-icon class="icon-btn" icon="bar-chart-steps" />
      </b-button>
    </template>

    <template #row-details="data">
      <b-card>
        <JugglProjectDistribution
          :projects="data.item.projects"
          names
          class="mb-3"
        />
        <JugglProjectStatisticsList :statistics="data.item.statistics" />
      </b-card>
    </template>
  </b-table>
</template>

<script>
import { helperService } from "@/services/helper.service.js";
import JugglProjectStatisticsList from "./JugglProjectStatisticsList.vue";
import JugglProjectDistribution from "./JugglProjectDistribution.vue";

export default {
  name: "JugglDailyStatisticsList",
  components: { JugglProjectStatisticsList, JugglProjectDistribution },
  props: {
    statistics: {
      required: true,
      type: Array
    }
  },
  data: () => {
    return {
      statistic_fields: [
        {
          key: "day",
          label: "Day"
        },
        {
          key: "duration",
          label: "Duration"
        },
        {
          key: "distribution",
          lavel: "Distribution"
        },
        {
          key: "record_count",
          label: "Records"
        },
        {
          key: "details",
          label: "Details"
        }
      ]
    };
  },
  computed: {
    isLoading: function() {
      return this.statistics === undefined;
    },
    aggregatedStatistics: function() {
      if (this.statistics === undefined) {
        return [];
      }

      var aggregated = {};
      this.statistics.forEach(stat => {
        var dayName = stat.date;

        if (aggregated[dayName] === undefined) {
          aggregated[dayName] = {
            day: dayName,
            duration: 0,
            record_count: 0,
            statistics: [],
            projects: {}
          };
        }

        if (aggregated[dayName].projects[stat.project_id] === undefined) {
          aggregated[dayName].projects[stat.project_id] = {
            project_id: stat.project_id,
            duration: 0,
            record_count: 0,
            color: stat.color,
            name: stat.name,
            visible: stat.visible,
            statistics: []
          };
        }

        aggregated[dayName].duration += Number(stat.duration);
        aggregated[dayName].record_count += Number(stat.record_count);
        aggregated[dayName].statistics.push(stat);

        aggregated[dayName].projects[stat.project_id].duration += Number(
          stat.duration
        );
        aggregated[dayName].projects[stat.project_id].record_count += Number(
          stat.record_count
        );
        aggregated[dayName].projects[stat.project_id].statistics.push(stat);
      });

      // Simplyfying object to lists
      aggregated = Object.values(aggregated);
      aggregated.forEach(stat => {
        stat.projects = Object.values(stat.projects);
      });
      return aggregated;
    }
  },
  methods: {
    getDurationTimestamp: helperService.getDurationTimestamp
  }
};
</script>

<style lang="sass"></style>
