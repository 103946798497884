<template>
  <b-form @submit="submitForm">
    <b-form-group id="id-group" label-for="id" label="Project ID">
      <b-form-input id="id" v-model="form.project_id" required trim disabled>
      </b-form-input>
    </b-form-group>
    <b-form-checkbox id="visible" v-model="form.visible" dark>
      Visible
    </b-form-checkbox>
    <b-form-group id="name-group" label-for="name" label="Name">
      <b-form-input id="name" v-model="form.name" required trim> </b-form-input>
    </b-form-group>
    <b-form-group id="startdate-group" label="Start date" label-for="startdate">
      <b-form-datepicker
        id="startdate"
        v-model="form.start_date"
        required
        placeholder="Choose a start date"
        dark
      >
      </b-form-datepicker>
    </b-form-group>
    <b-form-group id="color-group" label-for="color" label="Color">
      <b-form-input id="color" v-model="form.color" trim> </b-form-input>
    </b-form-group>
    <b-form-invalid-feedback :state="!failed">
      Something went wrong.
    </b-form-invalid-feedback>
    <b-button variant="primary" type="submit" class="right" :disabled="working">
      <b-spinner v-if="working" small />
      Save
    </b-button>
    <b-button variant="outline-secondary" @click="() => cancel()" class="left">
      Cancel
    </b-button>
    <BaseConfirmButton
      class="left"
      @click="() => deleteProject()"
      variant="outline-danger"
    >
      <b-icon class="icon-btn" icon="trash" />
    </BaseConfirmButton>
  </b-form>
</template>

<script>
import BaseConfirmButton from "@/components/base/BaseConfirmButton";
import store from "@/store";

export default {
  name: "FormProjectDetails",
  components: {
    BaseConfirmButton
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      failed: false,
      working: false,
      form: {
        project_id: undefined,
        start_date: undefined,
        name: undefined,
        color: undefined,
        visible: undefined
      }
    };
  },
  methods: {
    /**
     * Submits the form. Assupmtion: Form is valid, based on required flags.
     */
    submitForm: function(e) {
      e.preventDefault();
      this.failed = false;
      this.working = true;

      if (this.form.color == "") {
        this.form.color = null;
      }

      store
        .dispatch("updateProject", this.form)
        .then(() => {
          this.working = false;
          this.$emit("submit");
        })
        .catch(() => {
          this.failed = true;
          this.working = false;
        });

      return false;
    },
    cancel: function() {
      this.$emit("cancel");
    },
    deleteProject: function() {
      store.dispatch("removeProject", this.form.project_id);
    }
  },
  created: function() {
    this.form.project_id = this.project.project_id;
    this.form.name = this.project.name;
    this.form.start_date = this.project.start_date;
    this.form.color = this.project.color;
    this.form.visible = this.project.visible;
  }
};
</script>

<style lang="sass">
.left
    float: left !important
    margin-right: 1rem

.right
    float: right !important
    margin-left: 1rem
</style>
