<template>
  <LayoutNavbarPrivate title="Statistics">
    <BaseContainer class="centered mb-5">
      <b-form-radio-group
        id="mode-radio"
        v-model="mode"
        :options="options"
        button-variant="outline-primary"
        name="radio-btn-outline"
        buttons
        size="sm"
        class="mb-3"
        @input="updateStatistics"
      ></b-form-radio-group>
      <b-form id="form-daily" v-if="mode == 'daily'" inline>
        <b-form-datepicker
          id="startdate"
          v-model="daily.startDate"
          required
          placeholder="Choose a start date"
          :max="daily.endDate"
          dark
          @input="updateStatistics"
        >
        </b-form-datepicker>
        →
        <b-form-datepicker
          id="enddate"
          v-model="daily.endDate"
          required
          placeholder="Choose an end date"
          :min="daily.startDate"
          dark
          @input="updateStatistics"
        >
        </b-form-datepicker>
      </b-form>
      <b-form id="form-monthly" v-if="mode == 'monthly'" inline>
        <b-input-group>
          <b-input
            id="start-year"
            type="number"
            placeholder="Start year"
            v-model="monthly.startYear"
            required
            trim
            @input="updateStatistics"
            class="slim"
          >
          </b-input>
          <b-input
            id="start-month"
            type="number"
            placeholder="Start month"
            v-model="monthly.startMonth"
            required
            trim
            @input="updateStatistics"
            class="slim"
          >
          </b-input>
        </b-input-group>
        →
        <b-input-group>
          <b-input
            id="end-year"
            type="number"
            placeholder="End year"
            v-model="monthly.endYear"
            required
            trim
            @input="updateStatistics"
            class="slim"
          >
          </b-input>
          <b-input
            id="end-month"
            type="number"
            placeholder="End month"
            v-model="monthly.endMonth"
            required
            trim
            @input="updateStatistics"
            class="slim"
          >
          </b-input>
        </b-input-group>
      </b-form>
    </BaseContainer>

    <BaseSection id="projects" :title="modeTitle">
      <JugglDailyStatisticsList
        :statistics="visibleStatistics"
        v-if="mode == 'daily'"
      />
      <JugglMonthlyStatisticsList
        :statistics="visibleStatistics"
        v-if="mode == 'monthly'"
      />
    </BaseSection>
  </LayoutNavbarPrivate>
</template>

<script>
import LayoutNavbarPrivate from "@/components/layout/LayoutNavbarPrivate";
import JugglDailyStatisticsList from "@/components/juggl/JugglDailyStatisticsList";
import JugglMonthlyStatisticsList from "@/components/juggl/JugglMonthlyStatisticsList";
import { helperService } from "@/services/helper.service.js";
import BaseSection from "@/components/base/BaseSection";
import BaseContainer from "@/components/base/BaseContainer";
import store from "@/store";

export default {
  name: "Statistics",
  components: {
    LayoutNavbarPrivate,
    JugglMonthlyStatisticsList,
    JugglDailyStatisticsList,
    BaseSection,
    BaseContainer
  },
  data: () => {
    return {
      working: true,
      mode: "daily",
      options: [
        { text: "Daily", value: "daily" },
        { text: "Monthly", value: "monthly" }
      ],
      daily: {
        startDate: new Date(),
        endDate: new Date()
      },
      monthly: {
        startYear: new Date().getFullYear(),
        startMonth: new Date().getMonth() + 1,
        endYear: new Date().getFullYear(),
        endMonth: new Date().getMonth() + 1
      }
    };
  },
  mounted: function() {
    this.updateStatistics();
  },
  computed: {
    visibleStatistics: () => {
      return store.getters.getFilteredStatistics({
        projectVisible: true
      });
    },
    modeTitle: function() {
      return this.mode[0].toUpperCase() + this.mode.substring(1);
    }
  },
  methods: {
    getDurationTimestamp: helperService.getDurationTimestamp,
    updateStatistics: function() {
      if (this.mode == "daily") {
        store.dispatch("loadStatistics", {
          from: new Date(this.daily.startDate),
          until: new Date(this.daily.endDate)
        });
      } else if (this.mode == "monthly") {
        store.dispatch("loadMonthlyStatistics", this.monthly);
      }
    }
  }
};
</script>

<style lang="sass">
.centered
  text-align: center

#form-daily *, #form-monthly *
  margin: auto

.slim
  max-width: 6rem
</style>
