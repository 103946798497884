import axios from "axios";
import store from "@/store";

/**
 * A wrapper for the used fetch API, currently axios.
 * Uses some default values from the config (e.g. ApiUrl).
 *
 * Authentication already integrated.
 *
 * Returns promises.
 */
export const apiService = {
  get(resource, options = {}) {
    return this.getApi().get(resource, options);
  },

  post(resource, json = {}, options = {}) {
    return this.getApi().post(
      resource,
      { ...this.getDefaultJson(), ...json },
      options
    );
  },

  put(resource, json = {}, options = {}) {
    return this.getApi().put(
      resource,
      { ...this.getDefaultJson(), ...json },
      options
    );
  },

  /**
   * Creates an instance of the used api and sets necessary headers
   */
  getApi() {
    var options = {
      baseURL: store.getters.apiUrl
    };

    return axios.create(options);
  },
  getDefaultJson() {
    return {
      user_id: store.getters.auth.userId,
      api_key: store.getters.auth.apiKey
    };
  }
};
