<template>
  <section>
    <BaseTitle v-if="title" center size="huge">{{ title }}</BaseTitle>
    <slot />
  </section>
</template>

<script>
import BaseTitle from "@/components/base/BaseTitle";

export default {
  name: "BaseSection",
  components: {
    BaseTitle
  },
  props: {
    title: {
      required: false,
      default: ""
    }
  }
};
</script>

<style></style>
