<template>
  <LayoutMinimal center title="404">
    Couldn't find what you were looking for :(
  </LayoutMinimal>
</template>

<script>
// @ is an alias to /src
import LayoutMinimal from "@/components/layout/LayoutMinimal";

export default {
  name: "NotFound",
  components: {
    LayoutMinimal
  }
};
</script>
