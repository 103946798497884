<template>
  <b-progress :max="totalDuration">
    <b-progress-bar
      v-for="(project, index) in projects"
      v-bind:key="project.project_id"
      :value="project.duration"
      :style="'background-color: ' + project.color + ' !important'"
      :variant="index % 2 == 0 ? 'primary' : 'dark'"
      v-b-tooltip.hover
      :title="project.name + ' · ' + getDurationTimestamp(project.duration)"
      ><span v-if="names">{{ project.name }}</span></b-progress-bar
    >
  </b-progress>
</template>

<script>
import { helperService } from "@/services/helper.service.js";

export default {
  name: "JugglProjectDistribution",
  props: {
    projects: {
      required: true,
      type: Array
    },
    names: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    totalDuration: function() {
      var duration = 0;
      this.projects.forEach(stat => (duration += Number(stat.duration)));
      return duration;
    }
  },
  methods: {
    getDurationTimestamp: helperService.getDurationTimestamp
  }
};
</script>

<style></style>
