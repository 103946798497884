<template>
  <LayoutMinimal title="Changelog">
    <BaseSection>
      <BaseTitle size="small">
        11.02.2022
      </BaseTitle>
      <ul>
        <li>Implemented daily and monthly statistics</li>
        <li>Added tool</li>
      </ul>
    </BaseSection>

    <BaseSection>
      <BaseTitle size="small">
        23.11.2021
      </BaseTitle>
      <ul>
        <li>Added tools page</li>
        <li>Visual tweaks</li>
      </ul>
    </BaseSection>

    <BaseSection>
      <BaseTitle size="small">
        07.11.2021
      </BaseTitle>
      <ul>
        <li>Added credits page</li>
        <li>Created basic footer</li>
        <li>Added live record timer</li>
      </ul>
    </BaseSection>

    <BaseSection>
      <BaseTitle size="small">
        27.07.2021
      </BaseTitle>
      <ul>
        <li>Added simple statistics</li>
      </ul>
    </BaseSection>

    <BaseSection>
      <BaseTitle size="small">
        21.05.2021
      </BaseTitle>
      <ul>
        <li>Visual tweaks</li>
      </ul>
    </BaseSection>

    <BaseSection>
      <BaseTitle size="small">
        12.04.2021
      </BaseTitle>
      <ul>
        <li>Added toggle to change visibility of single projects and tags</li>
      </ul>
    </BaseSection>
  </LayoutMinimal>
</template>

<script>
// @ is an alias to /src
import LayoutMinimal from "@/components/layout/LayoutMinimal";
import BaseTitle from "@/components/base/BaseTitle";
import BaseSection from "../components/base/BaseSection.vue";

export default {
  name: "Changelog",
  components: {
    LayoutMinimal,
    BaseTitle,
    BaseSection
  }
};
</script>

<style lang="sass" scoped>
ul
  list-style-type: '+  '
</style>
