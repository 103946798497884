<template>
  <span class="box" :style="customStyle">{{ text }}</span>
</template>

<script>
export default {
  name: "BaseColorText",
  props: {
    text: {
      required: true
    },
    color: {
      required: false,
      default: "transparent"
    }
  },
  computed: {
    customStyle: function() {
      return "background-color: " + this.color;
    }
  }
};
</script>

<style lang="sass" scoped>
.box
    border-radius: 5px
    padding: 3px 10px
    text-align: left
</style>
