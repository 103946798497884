<template>
  <b-table
    :items="aggregatedStatistics"
    primary-key="month"
    hover
    :busy="isLoading"
    :fields="statistic_fields"
    sort-by="month"
    sort-desc
  >
    <template #table-busy>
      <div class="text-center">
        <b-spinner></b-spinner>
      </div>
    </template>

    <!-- Custom data -->
    <template #cell(duration)="data">
      {{ getDurationTimestamp(data.item.duration) }}
    </template>

    <template #cell(distribution)="data">
      <JugglProjectDistribution :projects="data.item.projects" class="mb-3" />
    </template>

    <template #cell(details)="data">
      <b-button
        size="sm"
        @click="data.toggleDetails"
        variant="outline-secondary"
      >
        <b-icon class="icon-btn" icon="bar-chart-steps" />
      </b-button>
    </template>

    <template #row-details="data">
      <b-card>
        <JugglProjectDistribution
          :projects="data.item.projects"
          names
          class="mb-3"
        />
        <JugglProjectStatisticsList :statistics="data.item.statistics" />
      </b-card>
    </template>
  </b-table>
</template>

<script>
import { helperService } from "@/services/helper.service.js";
import JugglProjectStatisticsList from "./JugglProjectStatisticsList.vue";
import JugglProjectDistribution from "./JugglProjectDistribution.vue";

export default {
  name: "JugglMonthlyStatisticsList",
  components: { JugglProjectStatisticsList, JugglProjectDistribution },
  props: {
    statistics: {
      required: true,
      type: Array
    }
  },
  data: () => {
    return {
      statistic_fields: [
        {
          key: "month",
          label: "Month"
        },
        {
          key: "duration",
          label: "Duration"
        },
        {
          key: "distribution",
          lavel: "Distribution"
        },
        {
          key: "record_count",
          label: "Records"
        },
        {
          key: "details",
          label: "Details"
        }
      ]
    };
  },
  computed: {
    isLoading: function() {
      return this.statistics === undefined;
    },
    aggregatedStatistics: function() {
      if (this.statistics === undefined) {
        return [];
      }

      var aggregated = {};
      this.statistics.forEach(stat => {
        var monthName = stat.date.substring(0, 7); // date is in the format "YYYY-MM-DD", so simply cutting of the day

        if (aggregated[monthName] === undefined) {
          aggregated[monthName] = {
            month: monthName,
            duration: 0,
            record_count: 0,
            statistics: [],
            projects: {}
          };
        }

        if (aggregated[monthName].projects[stat.project_id] === undefined) {
          aggregated[monthName].projects[stat.project_id] = {
            project_id: stat.project_id,
            duration: 0,
            record_count: 0,
            color: stat.color,
            name: stat.name,
            visible: stat.visible,
            statistics: []
          };
        }

        aggregated[monthName].duration += Number(stat.duration);
        aggregated[monthName].record_count += Number(stat.record_count);
        aggregated[monthName].statistics.push(stat);

        aggregated[monthName].projects[stat.project_id].duration += Number(
          stat.duration
        );
        aggregated[monthName].projects[stat.project_id].record_count += Number(
          stat.record_count
        );
        aggregated[monthName].projects[stat.project_id].statistics.push(stat);
      });

      // Simplyfying object to lists
      aggregated = Object.values(aggregated);
      aggregated.forEach(stat => {
        stat.projects = Object.values(stat.projects);
      });
      return aggregated;
    }
  },
  methods: {
    getDurationTimestamp: helperService.getDurationTimestamp
  }
};
</script>

<style lang="sass"></style>
