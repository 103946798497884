<template>
  <b-form @submit="submitForm">
    <b-form-group id="id-group" label-for="id" label="Record ID">
      <b-form-input id="id" v-model="record.record_id" required trim disabled>
      </b-form-input>
    </b-form-group>
    <b-form-group id="project-group" label-for="project" label="Project">
      <b-form-select
        id="project"
        v-model="record.project_id"
        :options="selectableProjects"
        required
      >
      </b-form-select>
    </b-form-group>
    <b-form-group id="startdate-group" label="Start date" label-for="startdate">
      <b-form-datepicker
        id="startdate"
        v-model="times.start.date"
        required
        placeholder="Choose a start date"
        :max="times.end.date"
        dark
        @input="() => updateCurrentDuration()"
      >
      </b-form-datepicker>
    </b-form-group>
    <b-form-group id="starttime-group" label="Start time" label-for="starttime">
      <b-form-timepicker
        id="starttime"
        v-model="times.start.time"
        show-seconds
        required
        placeholder="Choose a start time"
        dark
        @input="() => updateCurrentDuration()"
      >
      </b-form-timepicker>
    </b-form-group>
    <b-form-checkbox id="running" v-model="times.finished" required dark>
      Finished
    </b-form-checkbox>
    <b-form-group id="enddate-group" label="End date" label-for="enddate">
      <b-form-datepicker
        id="enddate"
        v-model="times.end.date"
        :required="times.finished"
        :disabled="!times.finished"
        placeholder="Choose an end date"
        :min="times.start.date"
        dark
        @input="() => updateCurrentDuration()"
      >
      </b-form-datepicker>
    </b-form-group>
    <b-form-group id="endtime-group" label="End time" label-for="endtime">
      <b-form-timepicker
        id="endtime"
        v-model="times.end.time"
        :required="times.finished"
        :disabled="!times.finished"
        show-seconds
        placeholder="Choose an end time"
        dark
        @input="() => updateCurrentDuration()"
      >
      </b-form-timepicker>
    </b-form-group>
    <b-form-group
      id="duration-group"
      label="Duration [seconds]"
      label-for="duration"
    >
      <b-input-group>
        <b-input-group-prepend
          is-text
          :class="{ disabled: !times.finished || !times.editableDuration }"
        >
          <b-form-checkbox
            class="mr-n2"
            v-model="times.editableDuration"
            @input="() => updateCurrentDuration()"
          >
          </b-form-checkbox>
        </b-input-group-prepend>
        <b-form-input
          id="duration"
          v-model="times.duration"
          type="number"
          :required="times.finished && !times.editableDuration"
          :disabled="!times.finished || !times.editableDuration"
          placeholder="Choose a duration"
          min="0"
          dark
        >
        </b-form-input>
      </b-input-group>
    </b-form-group>
    <b-form-invalid-feedback :state="!failed">
      Something went wrong.
    </b-form-invalid-feedback>
    <b-button variant="primary" type="submit" class="right" :disabled="working">
      <b-spinner v-if="working" small />
      {{ saveBtnText }}
    </b-button>
    <b-button variant="outline-secondary" @click="() => cancel()" class="left">
      Cancel
    </b-button>
    <BaseConfirmButton
      class="left"
      @click="() => deleteRecord()"
      variant="outline-danger"
    >
      <b-icon class="icon-btn" icon="trash" />
    </BaseConfirmButton>
  </b-form>
</template>

<script>
import { helperService } from "@/services/helper.service.js";
import BaseConfirmButton from "@/components/base/BaseConfirmButton";
import store from "@/store";

export default {
  name: "FormRecordDetails",
  components: {
    BaseConfirmButton
  },
  props: {
    record: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      times: {
        start: { date: undefined, time: undefined },
        end: { date: undefined, time: undefined },
        finished: false,
        duration: 0,
        editableDuration: false
      },
      selectableProjects: [],
      failed: false,
      working: false
    };
  },
  computed: {
    resultRecord: function() {
      var resultRecord = {
        record_id: this.record.record_id,
        user_id: this.record.user_id,
        project_id: this.record.project_id,
        running: !this.times.finished,
        start_time: this.times.start.date + " " + this.times.start.time,
        end_time: this.times.end.date + " " + this.times.end.time,
        duration: this.times.duration,
        tags: this.record.tags,
        start_device_id: this.record.start_device_id // TODO: Remove at some time
      };

      if (resultRecord.running) {
        resultRecord.end_time = null;
        resultRecord.duration = null;
      }

      return resultRecord;
    },
    saveBtnText: function() {
      var result = "Save";

      if (this.resultRecord.running !== this.record.running) {
        if (this.resultRecord.running) {
          result += " and track";
        } else {
          result += " and finish";
        }
      }

      return result;
    },
    exactCalcDuration: function() {
      return helperService.calcDurationInSeconds(
        this.resultRecord.start_time,
        this.resultRecord.end_time
      );
    }
  },
  methods: {
    /**
     * Submits the form. Assupmtion: Form is valid, based on required flags.
     */
    submitForm: function(e) {
      e.preventDefault();
      this.failed = false;
      this.working = true;

      store
        .dispatch("updateRecord", this.resultRecord)
        .then(() => {
          this.working = false;
          this.$emit("submit");
        })
        .catch(() => {
          this.failed = true;
          this.working = false;
        });

      return false;
    },
    cancel: function() {
      this.$emit("cancel");
    },
    deleteRecord: function() {
      store.dispatch("removeRecord", this.record.record_id);
    },
    updateCurrentDuration: function() {
      if (!this.times.editableDuration) {
        this.times.duration = this.exactCalcDuration;
      }
    }
  },
  created: function() {
    // Load selectable projects
    var projects = [];
    Object.values(store.getters.projects).forEach(project => {
      projects.push({ value: project.project_id, text: project.name });
    });
    this.selectableProjects = projects;

    // Load record times
    this.times.finished = !this.record.running;
    this.times.duration = Math.round(this.record.duration);

    let dateAndTime = /([0-9-]*)\s([0-9:]*)/;
    var startMatch = String(this.record.start_time).match(dateAndTime);
    this.times.start.date = startMatch[1];
    this.times.start.time = startMatch[2];

    var endMatch = String(this.record.end_time).match(dateAndTime);
    if (endMatch !== null) {
      this.times.end.date = endMatch[1];
      this.times.end.time = endMatch[2];
    } else {
      this.times.end.date = new Date().toISOString();
      this.times.end.time = helperService.toISOTime(new Date());
    }
  }
};
</script>

<style lang="sass">
.left
    float: left !important
    margin-right: 1rem

.right
    float: right !important
    margin-left: 1rem
</style>
