<template>
  <b-dropdown
    id="dropdown"
    :text="this.username"
    variant="outline-primary"
    right
  >
    <b-dropdown-item to="/statistics">Statistics</b-dropdown-item>
    <b-dropdown-item to="/logout">Log out</b-dropdown-item>
  </b-dropdown>
</template>

<script>
import store from "@/store";

export default {
  name: "BaseUserDropdown",
  computed: {
    username: () => {
      var user = store.getters.user;
      if (user === undefined) return "Loading";
      else return user.name;
    }
  }
};
</script>

<style lang="sass" scoped>
#dropdown
  *
    line-height: 1.5
</style>
