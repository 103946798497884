<template>
  <form @submit="submitForm">
    <b-input-group id="form">
      <b-form-input id="name" v-model="form.name" placeholder="Tag name" trim>
      </b-form-input>
      <b-input-group-append>
        <b-button variant="outline-secondary" type="submit" :disabled="working">
          <b-spinner v-if="working" small />
          Add tag
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </form>
</template>

<script>
import store from "@/store";

export default {
  name: "FormTagAdd",
  data() {
    return {
      form: {
        name: ""
      },
      working: false
    };
  },
  methods: {
    /**
     * Submits the form. Assupmtion: Form is valid, based on required flags.
     */
    submitForm: function(e) {
      e.preventDefault();

      if (this.form.name == "") {
        return false;
      }

      this.failed = false;
      this.working = true;

      // Try to login
      store
        .dispatch("addTag", {
          name: this.form.name
        })
        .then(() => {
          this.form.name = "";
          this.working = false;
          this.$emit("submit");
        })
        .catch(e => {
          console.log(e);

          this.working = false;
        });

      return false;
    }
  }
};
</script>

<style lang="sass">
#form
    max-width: 20rem
    margin: auto
</style>
