<template>
  <LayoutNavbarPrivate title="Manage structures">
    <BaseSection id="projects" title="Projects">
      <FormProjectAdd class="bottom-space" />
      <b-table
        :items="allProjects"
        hover
        :busy="working"
        :fields="project_fields"
        sort-by="name"
      >
        <template #table-busy>
          <div class="text-center">
            <b-spinner></b-spinner>
          </div>
        </template>

        <!-- Custom data -->
        <template #cell(name)="data">
          <JugglProjectName :project="data.item" />
        </template>

        <template #cell(duration)="data">
          {{ getDurationTimestamp(data.item.duration) }}
        </template>

        <template #cell(details)="data">
          <b-button
            size="sm"
            @click="data.toggleDetails"
            variant="outline-secondary"
          >
            <b-icon class="icon-btn" icon="gear" />
          </b-button>
        </template>

        <template #row-details="data">
          <b-card>
            <FormProjectDetails
              :project="data.item"
              @cancel="data.toggleDetails"
              @submit="data.toggleDetails"
            />
          </b-card>
        </template>
      </b-table>
    </BaseSection>
    <BaseSection id="tags" title="Tags">
      <FormTagAdd class="bottom-space" />
      <b-table
        :items="allTags"
        hover
        :busy="working"
        :fields="tag_fields"
        sort-by="name"
      >
        <template #table-busy>
          <div class="text-center">
            <b-spinner></b-spinner>
          </div>
        </template>

        <!-- Custom data -->
        <template #cell(details)="data">
          <b-button
            size="sm"
            @click="data.toggleDetails"
            variant="outline-secondary"
          >
            <b-icon class="icon-btn" icon="gear" />
          </b-button>
        </template>

        <template #row-details="data">
          <b-card>
            <FormTagDetails
              :tag="data.item"
              @cancel="data.toggleDetails"
              @submit="data.toggleDetails"
            />
          </b-card>
        </template>
      </b-table>
    </BaseSection>
  </LayoutNavbarPrivate>
</template>

<script>
import LayoutNavbarPrivate from "@/components/layout/LayoutNavbarPrivate";
import FormProjectDetails from "@/components/forms/FormProjectDetails";
import JugglProjectName from "@/components/juggl/JugglProjectName";
import FormProjectAdd from "@/components/forms/FormProjectAdd";
import FormTagDetails from "@/components/forms/FormTagDetails";
import { helperService } from "@/services/helper.service.js";
import BaseSection from "@/components/base/BaseSection";
import FormTagAdd from "@/components/forms/FormTagAdd";
import store from "@/store";

export default {
  name: "Manage",
  components: {
    LayoutNavbarPrivate,
    FormProjectDetails,
    JugglProjectName,
    FormProjectAdd,
    FormTagDetails,
    BaseSection,
    FormTagAdd
  },
  data: () => {
    return {
      working: true,
      project_fields: [
        {
          key: "name",
          label: "Name"
        },
        {
          key: "start_date",
          label: "Start date"
        },
        {
          key: "duration",
          label: "Duration"
        },
        {
          key: "record_count",
          label: "Records"
        },
        {
          key: "details",
          label: "Details"
        }
      ],
      tag_fields: [
        {
          key: "name",
          label: "Name"
        },
        {
          key: "details",
          label: "Details"
        }
      ]
    };
  },
  created: function() {
    store.dispatch("loadProjects");
    store
      .dispatch("loadTags")
      .then(() => {
        this.working = false;
      })
      .catch(() => {
        this.working = false;
      });
  },
  computed: {
    allTags: function() {
      return Object.values(store.getters.tags);
    },
    allProjects: function() {
      return Object.values(store.getters.projects);
    }
  },
  methods: {
    getDurationTimestamp: helperService.getDurationTimestamp
  }
};
</script>

<style lang="sass">
.center
  text-align: center

.bottom-space
  margin-bottom: 1rem

section
  margin-bottom: 4rem
</style>
