<template>
  <b-table
    :items="projectStatistics"
    primary-key="project_id"
    hover
    :busy="isLoading"
    :fields="statistic_fields"
    sort-by="duration"
    sort-desc
  >
    <template #table-busy>
      <div class="text-center">
        <b-spinner></b-spinner>
      </div>
    </template>

    <!-- Custom data -->
    <template #cell(project)="data">
      <JugglProjectName :project="data.item" />
    </template>

    <template #cell(duration)="data">
      {{ getDurationTimestamp(data.item.duration) }}
    </template>

    <template #cell(distribution)="data">
      {{ ((data.item.duration / totalDuration) * 100).toFixed(0) }}%
    </template>
  </b-table>
</template>

<script>
import JugglProjectName from "@/components/juggl/JugglProjectName";
import { helperService } from "@/services/helper.service.js";

export default {
  name: "JugglProjectStatisticsList",
  components: {
    JugglProjectName
  },
  props: {
    statistics: {
      required: true,
      type: Array
    }
  },
  data: () => {
    return {
      statistic_fields: [
        {
          key: "project",
          label: "Project"
        },
        {
          key: "duration",
          label: "Duration"
        },
        {
          key: "record_count",
          label: "Records"
        },
        {
          key: "distribution",
          label: "Distribution"
        }
      ]
    };
  },
  computed: {
    isLoading: function() {
      return this.statistics === undefined;
    },
    totalDuration: function() {
      var duration = 0;
      this.statistics.forEach(stat => (duration += Number(stat.duration)));
      return duration;
    },
    projectStatistics: function() {
      if (this.statistics === undefined) {
        return [];
      }

      var projects = {};
      this.statistics.forEach(stat => {
        if (projects[stat.project_id] === undefined) {
          projects[stat.project_id] = {
            project_id: stat.project_id,
            duration: 0,
            record_count: 0,
            color: stat.color,
            name: stat.name,
            visible: stat.visible,
            statistics: []
          };
        }

        projects[stat.project_id].duration += Number(stat.duration);
        projects[stat.project_id].record_count += Number(stat.record_count);
        projects[stat.project_id].statistics.push(stat);
      });

      // Simplyfying object to lists
      return Object.values(projects);
    }
  },
  methods: {
    getDurationTimestamp: helperService.getDurationTimestamp
  }
};
</script>

<style lang="sass"></style>
