<template>
  <h1
    id="title"
    :class="[size, { center: center }, { outline: outline }]"
    class="bold"
  >
    <slot />
  </h1>
</template>

<script>
export default {
  name: "BaseTitle",
  props: {
    size: {
      default: "medium",
      type: String
    },
    center: {
      default: true,
      type: Boolean
    },
    outline: {
      default: false,
      type: Boolean
    }
  }
};
</script>

<style lang="sass" scoped>
@import '@/style/theme.sass'

#title.tiny
  font-size: 1em
#title.small
  font-size: 1.1em
#title.medium
  font-size: 1.3em
#title.large
  font-size: 1.7em
#title.huge
  font-size: 2em
#title.giant
  font-size: 3em

#title.center
  text-align: center

#title.outline
  color: transparent
  -webkit-text-stroke-width: 1.5px
  -webkit-text-stroke-color: $secondary

.bold
  font-weight: bold
</style>
