<template>
  <footer>
    <BaseContainer
      width="medium"
      center
    >
      <b-link to="/tools" class="surround-space">Tools</b-link>
      <b-link to="/changelog" class="surround-space">Changelog</b-link>
      <b-link to="/credits" class="surround-space">Credits</b-link>
    </BaseContainer>
  </footer>
</template>

<script>
import BaseContainer from "@/components/base/BaseContainer.vue";

export default {
  name: "LayoutFooter",
  components: {
    BaseContainer,
  },
};
</script>

<style lang="sass" scoped>
footer
  margin-bottom: 3rem
  text-align: center

.surround-space
  margin-left: 1rem
  margin-right: 1rem
</style>
