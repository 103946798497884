<template>
  <b-table
    :items="records"
    primary-key="record_id"
    hover
    :busy="isLoading"
    :fields="fields"
    sort-by="start"
    :sort-desc="sortDesc"
  >
    <template #table-busy>
      <div class="text-center">
        <b-spinner></b-spinner>
      </div>
    </template>

    <!-- Custom data -->
    <template #cell(project)="data">
      <JugglProjectName :project="getProject(data.item.project_id)" />
    </template>

    <template #cell(start)="data">
      {{ data.item.start_time }}
    </template>

    <template #cell(duration)="data">
      {{ getDurationTimestamp(data.item.duration) }}
    </template>

    <template #cell(tags)="data">
      <JugglTagField
        :recordId="data.item.record_id"
        :onlyVisible="onlyVisibleTags"
      />
    </template>

    <template #cell(details)="data">
      <b-button
        size="sm"
        @click="data.toggleDetails"
        variant="outline-secondary"
      >
        <b-icon class="icon-btn" icon="gear" />
      </b-button>
    </template>

    <template #cell(stop)="data">
      <b-button
        size="sm"
        @click="stopRecord(data.item.record_id)"
        variant="outline-success"
      >
        <b-icon class="icon-btn" icon="check" />
      </b-button>
    </template>

    <template #row-details="data">
      <b-card>
        <FormRecordDetails
          :record="data.item"
          @cancel="data.toggleDetails"
          @submit="data.toggleDetails"
        />
      </b-card>
    </template>
  </b-table>
</template>

<script>
import FormRecordDetails from "@/components/forms/FormRecordDetails";
import JugglProjectName from "@/components/juggl/JugglProjectName";
import JugglTagField from "@/components/juggl/JugglTagField";
import { helperService } from "@/services/helper.service.js";
import store from "@/store";

export default {
  name: "JugglRecordsList",
  components: {
    FormRecordDetails,
    JugglProjectName,
    JugglTagField
  },
  props: {
    records: {
      required: true,
      type: Array
    },
    sortDesc: {
      required: false,
      type: Boolean,
      default: true
    },
    running: {
      required: false,
      type: Boolean,
      default: false
    },
    onlyVisibleTags: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      iconScale: 1.6,
      timerDelta: 0,
      requiredFields: [
        {
          key: "project",
          label: "Project"
        },
        {
          key: "start",
          label: "Start"
        },
        {
          key: "duration",
          label: "Duration"
        },
        {
          key: "tags",
          label: "Tags"
        },
        {
          key: "details",
          label: "Details"
        }
      ],
      runningFields: [
        {
          key: "stop",
          label: "Finish"
        }
      ]
    };
  },
  computed: {
    isLoading: function() {
      return this.records === undefined;
    },
    fields: function() {
      var fields = this.requiredFields;

      if (this.running) {
        fields = [...fields, ...this.runningFields];
      }

      return fields;
    }
  },
  timers: {
    "increaseTimer": { time: 1000, autostart: true, repeat: true }
  },
  methods: {
    getDurationTimestamp: function(duration) {
      var localDuration = duration;
      if (this.running) {
        localDuration += this.timerDelta;
      }

      return helperService.getDurationTimestamp(localDuration);
    },
    getProject: function(id) {
      var project = store.getters.getProjectById(id);

      if (project === undefined) {
        return {};
      }

      return project;
    },
    stopRecord: function(id) {
      store.dispatch("endRecord", id);
    },
    detailsRecord: function(id) {
      this.$router.push("/record/" + id);
    },
    increaseTimer: function() {
      if (this.records.length <= 0) {
        return;
      }

      var record = this.records[0];
      var liveDuration = (new Date().getTime() - new Date(record.start_time).getTime()) / 1000;
      this.timerDelta = liveDuration - record.duration;
    }
  }
};
</script>

<style lang="sass">
.icon-btn:hover
  color: green
  cursor: pointer
</style>
