import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Login from "../views/Login.vue";
import NotFound from "../views/NotFound.vue";
import Home from "../views/Home.vue";
import History from "../views/History.vue";
import Manage from "../views/Manage.vue";
import Statistics from "../views/Statistics.vue";
import Changelog from "../views/Changelog.vue";
import Credits from "../views/Credits.vue";
import ToolsDocumentation from "../views/ToolsDocumentation.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home"
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/history",
    name: "History",
    component: History,
    beforeEnter: requireAuth
  },
  {
    path: "/manage",
    name: "Manage",
    component: Manage,
    beforeEnter: requireAuth
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    beforeEnter: requireAuth
  },
  {
    path: "/statistics",
    name: "Statistics",
    component: Statistics,
    beforeEnter: requireAuth
  },
  {
    path: "/changelog",
    name: "Changelog",
    component: Changelog
  },
  {
    path: "/credits",
    name: "Credits",
    component: Credits
  },
  {
    path: "/tools",
    name: "ToolsDocumentation",
    component: ToolsDocumentation
  },
  {
    path: "/logout",
    name: "Logout",
    beforeEnter: (to, from, next) => {
      store.dispatch("logout");
      next("/");
    }
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

/**
 * Checks authentication before proceeding
 */
function requireAuth(to, from, next) {
  store.dispatch("loadSavedLogin");

  if (!store.getters.isLoggedIn) {
    next({
      path: "/login",
      query: { redirect: to.fullPath }
    });
  } else {
    next();
  }
}

export default router;
