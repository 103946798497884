import { apiService } from "@/services/api.service";
import { helperService } from "@/services/helper.service";

/**
 * A collection of functions to retreive and send all user-specific data.
 */
export const jugglService = {
  /**
   * Fetches the user from the API.
   *
   * @returns A promise
   */
  getUser() {
    return apiService.post("/getUser.php").then(r => {
      return {
        data: r.data,
        msg: ""
      };
    });
  },

  getProjects() {
    return apiService.post("/getProjects.php").then(r => {
      return {
        data: { projects: processProjects(r.data.projects) },
        msg: ""
      };
    });
  },

  getTags() {
    return apiService.post("/getRecordTags.php").then(r => {
      return {
        data: { record_tags: processTags(r.data.record_tags) },
        msg: ""
      };
    });
  },

  getStatistics(options = {}) {
    if (options.from === undefined) {
      options.from = new Date();
    }
    if (options.until === undefined) {
      options.until = new Date();
    }

    return apiService
      .post("/getStats.php", {
        from_date: helperService.toISODateOnly(options.from),
        until_date: helperService.toISODateOnly(options.until)
      })
      .then(r => {
        return {
          data: { statistics: processVisibility(r.data.stats) },
          msg: ""
        };
      });
  },

  getRecord(recordId) {
    return apiService
      .post("/getRecord.php", {
        record_id: recordId
      })
      .then(r => {
        return {
          data: processRecords(r.data),
          msg: ""
        };
      });
  },

  removeRecord(recordId) {
    return apiService
      .post("/removeRecord.php", {
        record_id: recordId
      })
      .then(r => {
        return {
          data: r.data,
          msg: ""
        };
      });
  },

  removeProject(projectId) {
    return apiService
      .post("/removeProject.php", {
        project_id: projectId
      })
      .then(r => {
        return {
          data: r.data,
          msg: ""
        };
      });
  },

  removeRecordTag(tagId) {
    return apiService
      .post("/removeRecordTag.php", {
        record_tag_id: tagId
      })
      .then(r => {
        return {
          data: r.data,
          msg: ""
        };
      });
  },

  getRecords(options = {}) {
    var payload = {};

    if (options.limit !== undefined && options.limit > 0) {
      payload.limit = options.limit;
    }
    if (options.finished !== undefined) {
      payload.finished = options.finished;
    }
    if (options.visible !== undefined) {
      payload.visible = options.visible;
    }

    return apiService.post("/getRecords.php", payload).then(r => {
      return {
        data: processRecords(r.data),
        msg: ""
      };
    });
  },

  updateRecord(record) {
    var payload = {
      record: record
    };

    return apiService.post("/updateRecord.php", payload).then(r => {
      return {
        data: processRecords(r.data),
        msg: ""
      };
    });
  },

  updateProject(project) {
    var payload = {
      project: project
    };

    return apiService.post("/updateProject.php", payload).then(r => {
      return {
        data: r.data,
        msg: ""
      };
    });
  },

  updateRecordTag(tag) {
    var payload = {
      record_tag: tag
    };

    return apiService.post("/updateRecordTag.php", payload).then(r => {
      return {
        data: r.data,
        msg: ""
      };
    });
  },

  getRunningRecords() {
    return apiService.post("/getRunningRecords.php").then(r => {
      return {
        data: processRecords(r.data),
        msg: ""
      };
    });
  },

  addProject(name, startDate = null) {
    if (startDate == null) startDate = new Date().toISOString();
    return apiService
      .post("/addProject.php", {
        name: name,
        start_date: startDate
      })
      .then(r => {
        return {
          data: r.data,
          msg: ""
        };
      });
  },

  addTag(name) {
    return apiService
      .post("/addRecordTag.php", {
        tag_name: name
      })
      .then(r => {
        return {
          data: r.data,
          msg: ""
        };
      });
  },

  addTagToRecord(tagId, recordId) {
    return apiService
      .post("/addTagToRecord.php", {
        tag_id: tagId,
        record_id: recordId
      })
      .then(r => {
        return {
          data: r.data,
          msg: ""
        };
      });
  },

  removeTagFromRecord(tagId, recordId) {
    return apiService
      .post("/removeTagFromRecord.php", {
        tag_id: tagId,
        record_id: recordId
      })
      .then(r => {
        return {
          data: r.data,
          msg: ""
        };
      });
  },

  startRecord(projectId, startTime = null) {
    if (startTime == null) startTime = new Date();
    return apiService
      .post("/startRecord.php", {
        project_id: projectId,
        start_time: helperService.dateToString(startTime)
      })
      .then(r => {
        return {
          data: processRecords(r.data),
          msg: ""
        };
      });
  },

  endRecord(recordId, endTime = null) {
    if (endTime == null) endTime = new Date();
    return apiService
      .post("/endRecord.php", {
        record_id: recordId,
        end_time: helperService.dateToString(endTime)
      })
      .then(r => {
        return {
          data: processRecords(r.data),
          msg: ""
        };
      });
  }
};

function processRecords(data) {
  Object.values(data.records).forEach(rec => {
    if (rec.running) {
      rec.duration = helperService.calcDurationInSeconds(rec.start_time);
    }

    rec.tags = processTags(Object.values(rec.tags));
  });
  return data;
}

function processTags(tags) {
  return processVisibility(tags);
}

function processProjects(projects) {
  return processVisibility(projects);
}

function processVisibility(items) {
  Object.values(items).forEach(item => {
    if (item.visible === "1") {
      item.visible = true;
    } else {
      item.visible = false;
    }
  });
  return items;
}
