<template>
  <div>
    <b-link to="/">
      <b-img :src="image" alt="Juggl" :width="widthSize" :center="center" />
    </b-link>
  </div>
</template>

<script>
import logo from "./../../assets/logo.png";
import title from "./../../assets/title.png";

export default {
  name: "BaseLogo",
  props: {
    size: {
      default: "small",
      type: String
    },
    center: {
      default: false,
      type: Boolean
    },
    iconOnly: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    widthSize: function() {
      let titleSizes = {
        mini: "35px",
        tiny: "80px",
        smaller: "110px",
        small: "150px",
        normal: "175px",
        medium: "300px",
        large: "450px",
        big: "600px",
        huge: "800px",
        massive: "960px"
      };
      let logoSizes = {
        mini: "12px",
        tiny: "30px",
        smaller: "50px",
        small: "65px",
        normal: "80x",
        medium: "100px",
        large: "150px",
        big: "200px",
        huge: "300px",
        massive: "400px"
      };

      var sizes = titleSizes;
      if (this.iconOnly) {
        sizes = logoSizes;
      }

      let targetSize = sizes[this.size];
      if (targetSize === undefined) return sizes["small"];
      else return targetSize;
    },
    image: function() {
      if (this.iconOnly) {
        return logo;
      } else {
        return title;
      }
    }
  }
};
</script>

<style />
