<template>
  <b-container id="grid" fluid>
    <b-row>
      <b-col id="column" :class="[width, { 'mx-auto': center }]">
        <slot />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "BaseContainer",
  props: {
    width: {
      default: "medium",
      type: String
    },
    center: {
      default: true,
      type: Boolean
    }
  }
};
</script>

<style lang="sass" scoped>
#column.slimmer
  max-width: 300px

#column.slim
  max-width: 450px

#column.medium
  max-width: 800px

#column.wide
  max-width: 1000px

#column.open
  max-width: inherit
</style>
