<template>
  <div>
    <b-button
      :pressed.sync="triggered"
      :size="size"
      class="inline"
      :variant="variant"
    >
      <slot />
    </b-button>

    <div id="confirm-popover" v-if="triggered" class="inline">
      <div class="inline">{{ msg }}</div>
      <b-button
        @click="triggered = false"
        :size="size"
        class="inline"
        variant="outline-secondary"
      >
        Cancel
      </b-button>
      <b-button
        @click="() => confirm()"
        :size="size"
        class="inline"
        variant="outline-danger"
      >
        Yes
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseConfirmButton",
  props: {
    size: {
      required: false,
      type: String
    },
    variant: {
      required: false,
      type: String,
      default: "outline-danger"
    },
    msg: {
      required: false,
      type: String,
      default: "Sure?"
    }
  },
  data() {
    return {
      triggered: false
    };
  },
  methods: {
    confirm: function() {
      this.$emit("click");
    }
  }
};
</script>

<style lang="sass" scoped>
.inline
  display: inline-block

#confirm-popover *
  margin-left: 1rem
</style>
