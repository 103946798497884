<template>
  <div id="project-list">
    <div
      v-for="project in projects"
      :key="project.project_id"
      @click="() => startProject(project.project_id)"
    >
      <JugglProjectName :project="project" class="project-title" />
      <p>{{ getDurationTimestamp(project.duration) }}</p>
      <p>{{ project.record_count }} records</p>
    </div>
  </div>
</template>

<script>
import JugglProjectName from "@/components/juggl/JugglProjectName";
import { helperService } from "@/services/helper.service.js";
import store from "@/store";

export default {
  name: "JugglProjectPanel",
  components: {
    JugglProjectName
  },
  props: {
    projects: {
      required: true
    }
  },
  methods: {
    getDurationTimestamp: helperService.getDurationTimestamp,
    startProject: function(id) {
      store.dispatch("startRecord", id);
    }
  }
};
</script>

<style lang="sass" scoped>
@import '@/style/theme.sass'

#project-list
  display: flex
  flex-direction: row
  flex-wrap: wrap
  justify-content: center
  align-content: flex-start
  padding: 5px

  > *
    margin: 5px
    border: 1px dashed $grey
    border-radius: 5px
    padding: 10px
    text-align: center

    &:hover
      border-color: $primary !important
      cursor: pointer

  .project-title
    font-weight: bold
    font-size: 24pt

  p
    font-size: 10pt
    color: $grey
    margin: 0px
    padding: 0px
</style>
