<template>
  <LayoutNavbarPrivate title="History">
    <div class="center" v-if="working">
      <b-spinner></b-spinner>
    </div>
    <section v-if="!working">
      <div class="controls">
        <b-button
          :download="downloadFilename"
          :href="
            'data:text/plain;charset=utf-8,' + encodeURIComponent(fileOutput)
          "
          variant="outline-secondary"
          >Download data</b-button
        >
      </div>
      <JugglRecordsList :records="finishedRecords" />
    </section>
  </LayoutNavbarPrivate>
</template>

<script>
import LayoutNavbarPrivate from "@/components/layout/LayoutNavbarPrivate";
import JugglRecordsList from "@/components/juggl/JugglRecordsList";
import { helperService } from "@/services/helper.service.js";
import store from "@/store";

export default {
  name: "History",
  data: () => {
    return {
      working: true
    };
  },
  components: {
    LayoutNavbarPrivate,
    JugglRecordsList
  },
  computed: {
    finishedRecords: () => {
      return store.getters.finishedRecords;
    },
    downloadFilename: function() {
      return (
        "juggl_data_" + helperService.dateAsFilenameString(new Date()) + ".json"
      );
    },
    fileOutput: function() {
      var content = {
        user: store.getters.user,
        tags: store.getters.tags,
        projects: store.getters.projects,
        records: store.getters.records
      };

      return JSON.stringify(content);
    }
  },
  created: function() {
    store.dispatch("loadTags");
    store.dispatch("loadProjects");
    store
      .dispatch("loadRecords", { limit: 0, finished: true, visible: true })
      .then(() => {
        this.working = false;
      })
      .catch(() => {
        this.working = false;
      });
  },
  beforeDestroy: function() {
    store.dispatch("removeLocalRecords");
  }
};
</script>

<style lang="sass">
.center
  text-align: center

.controls
  margin-bottom: 1rem
  text-align: right

section
  margin-bottom: 4rem
</style>
